import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../../components/layout';
import HowToUse from '../../components/HowToUse';
import { genHowToUseBreadcrumbJsonLd } from '../../utils/jsonld';

const meta = {
  en: {
    title: 'Customized Evernote highlight color - EverTool',
    subtitle: 'Use any highlight color in Evernote',
  },
  'zh-tw': {
    title: '客製化 Evernote highlight 顏色 - EverTool',
    subtitle: '在 Evernote 使用任意的 highlight 顏色',
  },
  'zh-cn': {
    title: '客制化印象笔记的高亮颜色 - EverTool',
    subtitle: '在印象笔记使用任意的高亮顏色',
  },
  ja: {
    title: 'カスタマイズされたEvernoteのハイライトカラー - EverTool',
    subtitle: 'Evernoteの内の任意のハイライトカラーを使用します',
  },
};


class CustomizedHighlightCodePage extends React.Component {
  constructor(props) {
    super(props);
    const { data } = props;
    const config = {
      images: {
        mac: {
          intro1: [data.intro1_1, data.intro1_2],
          intro2: [data.intro2_1, data.intro2_2],
          intro3: [data.intro3],
        },
      },
      introId: {
        title: 'highlight.title',
        step1: 'highlight.step1',
        step2: 'highlight.step2',
        step3: 'highlight.step3',
      },
    };
    this.state = {
      config,
    };
  }

  render() {
    const { pageContext } = this.props;
    const { config } = this.state;
    const { locale, url } = pageContext;

    return (
      <Layout
        pageContext={pageContext}
        seo={{
          title: meta[locale].title,
          subtitle: meta[locale].subtitle,
          hreflangs: pageContext.hreflangs,
          url,
          locale,
          jsonld: genHowToUseBreadcrumbJsonLd(meta[locale].title, url, locale),
        }}
      >
        <div className="how-to-wrapper">
          <p className="center">Release in version 0.3.0 and later</p>
          <HowToUse
            locale={locale}
            pageContext={pageContext}
            url={url}
            config={config}
            oneSystem="mac"
          />
        </div>
      </Layout>
    );
  }
}

export default CustomizedHighlightCodePage;

export const pageQuery = graphql`
  query HowToHighlightQuery {
    site {
      siteMetadata {
        title
        subtitle
        url
      }
    }
    intro1_1: file(relativePath: { eq: "how-to/customized-highlight/mac/step1-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    intro1_2: file(relativePath: { eq: "how-to/customized-highlight/mac/step1-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    intro2_1: file(relativePath: { eq: "how-to/customized-highlight/mac/step2-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    intro2_2: file(relativePath: { eq: "how-to/customized-highlight/mac/step2-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    intro3: file(relativePath: { eq: "how-to/customized-highlight/mac/step3.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
